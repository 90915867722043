import React, { useCallback, useEffect, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import Draggable from 'react-draggable';
import { Endpoint } from '@jsplumb/community/types/core';
import HolderOutlined from '@ant-design/icons/HolderOutlined';
import CaretRightFilled from '@ant-design/icons/CaretRightFilled';
import CaretDownFilled from '@ant-design/icons/CaretDownFilled';
import { eventHistory } from '@kemu-io/kemu-core/common';
import { EventHistoryItem } from '@kemu-io/kemu-core/types';
import { DataType } from '@kemu-io/hs-types';
import classNames from 'classnames';
import { Inspector } from 'react-inspector';
import styles from './WidgetPortInspector.module.css';
import { customTheme } from './inspectorTheme.ts';
import { LM_CANVAS_CONTAINER_CLASS, GATE_PORT_CLASS, TRIGGER_PORT_CLASS } from '@common/constants';
import { dataTypeToHumanReadable, decodeDomId } from '@common/utils';
import { KemuEndpointData } from '@src/types/core_t';
import { useTranslation } from '@hooks/index';

const minContractedPanelSize = { width: 166, height: 90 };
const minExpandedPanelSize = { width: 166, height: 200 };

type ElementWithJTKEndpoint = HTMLElement & {
  jtk?: {
    endpoint: Endpoint;
  }
}

type PortInfo = {
  isOutput: boolean;
  portType: DataType | DataType[];
  lastTimestamp?: number;
  lastDetectedEventType?: DataType;
  lastDetectedValue?: string | number | boolean | Record<string, unknown>;
  lastValueIsObject?: boolean;
  isTriggerPort?: boolean;
}


const WidgetPortInspector = () => {
  const [panelSize, setPanelSize] = useState<{ width: number, height: number }>({ ...minContractedPanelSize });
  const [portInfo, setPortInfo] = useState<PortInfo | null>(null);
  const [lastExpandedSize, setLastExpandedSize] = useState<{ width: number, height: number }>({ ...minExpandedPanelSize });
  const [expandObjectDetails, setExpandObjectDetails] = useState(false);
  const t = useTranslation();
  const wpT = t.withBaseKey('LogicMapper.WidgetPortDebugPanel');
  const portTypes = Array.isArray(portInfo?.portType) ? portInfo?.portType : [portInfo?.portType];
  const portTypeNames = portTypes?.map((type) => dataTypeToHumanReadable(t, type as DataType));
  const showDetailsPanel = portInfo?.lastValueIsObject && expandObjectDetails;

  const handleResize = (event: React.SyntheticEvent, data: ResizeCallbackData) => {
    if (expandObjectDetails) {
      setLastExpandedSize({
        width: Math.max(minContractedPanelSize.width, data.size.width),
        height: Math.max(minContractedPanelSize.height, data.size.height),
      });
    }
    setPanelSize({
      width: Math.max(minContractedPanelSize.width, data.size.width),
      height: Math.max(minContractedPanelSize.height, data.size.height),
    });
  };

  const togglePanelExpansion = () => {
    const nextExpanded = !expandObjectDetails;
    if (nextExpanded) {
      // Make sure the expanded dimensions are large enough
      const nextW = panelSize.height < minExpandedPanelSize.height ? minExpandedPanelSize.height : panelSize.height;
      const nextH = panelSize.width < minExpandedPanelSize.width ? minExpandedPanelSize.width : panelSize.width;
      setPanelSize({ width: Math.max(lastExpandedSize.width, nextW), height: Math.max(lastExpandedSize.height, nextH) });
    } else {
      setPanelSize({ width: minContractedPanelSize.width, height: minContractedPanelSize.height });
    }

    setExpandObjectDetails(!expandObjectDetails);
  };

  const processPortClick = useCallback((e: HTMLElement) => {
    const target = e as ElementWithJTKEndpoint;
    const isTriggerPort = target.classList.contains(TRIGGER_PORT_CLASS);
    const endpointPort = target.jtk?.endpoint;
    const endpointElement = endpointPort?.element as HTMLElement;
    const widgetInfo = decodeDomId(endpointElement.id);
    const portData = endpointPort?.getData() as KemuEndpointData;
    let history: EventHistoryItem | null = null;

    if (widgetInfo?.gateId) {
      history = eventHistory.getLastProducedPortEvent(widgetInfo?.blockId, widgetInfo?.gateId, portData.name);
    }

    setPortInfo({
      isOutput: true,
      portType: portData.type,
      lastDetectedEventType: history?.data.type as DataType,
      lastDetectedValue: history?.data.value as string | number | boolean | Record<string, unknown>,
      lastTimestamp: history?.data.timestamp as number,
      lastValueIsObject: history?.data.value instanceof Object,
      isTriggerPort,
    });
  }, []);

  useEffect(() => {
    const canvasContainer = document.querySelector(`.${LM_CANVAS_CONTAINER_CLASS}`);

    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains(GATE_PORT_CLASS)) {
        processPortClick(target);
      }
    };

    canvasContainer?.addEventListener('click', handleClick as EventListener);

    return () => {
      canvasContainer?.removeEventListener('click', handleClick as EventListener);
    };
  }, [processPortClick]);

  return (
    <Draggable
      handle={`.${styles.DragHandle}`}
      bounds="parent"
      defaultPosition={{
        x: 20,
        y: 65,
      }}
    >
      <Resizable
        height={panelSize.height}
        width={panelSize.width}
        minConstraints={showDetailsPanel
          ? [minExpandedPanelSize.width, minExpandedPanelSize.height]
          : [minContractedPanelSize.width, minContractedPanelSize.height]
        }
        resizeHandles={['se']}
        onResize={handleResize}
      >
        <div
          className={styles.Inspector}
          style={{
            width: panelSize.width,
            height: panelSize.height,
          }}
        >
          <div className={styles.DragHandle}>
            <span className={styles.DragIcon}>
              <HolderOutlined />
            </span>
            <span className={styles.DragText}>
              {wpT('Title')}
            </span>
          </div>

          <div className={styles.InspectorBody}>
            {!portInfo && (
              <div className={styles.NoData}>
                {wpT('ClickToInspect')}
              </div>
            )}


            {portInfo?.isOutput && (
              <>
                <div className={styles.LineItem}>
                  <div className={styles.LineItemLabel}>{wpT('Type', 'Type')}:</div>
                  <div className={styles.LineItemValue}>
                  {portTypeNames?.length ? portTypeNames?.join(', ') : wpT('NotAvailable', 'N/A')}
                  </div>
                </div>

                <div className={styles.LineItem}>
                  <div className={styles.LineItemLabel}>{wpT('LastValue', 'Last Value')}:</div>
                  <div className={
                    classNames(styles.LineItemValue, styles.Result, {
                      [styles.Clickable]: portInfo?.lastValueIsObject,
                    })}
                    onClick={portInfo?.lastValueIsObject ? togglePanelExpansion : undefined}
                  >
                    {portInfo?.lastValueIsObject ? (
                      <>
                        <span>
                          {Array.isArray(portInfo.lastDetectedValue)
                            ? dataTypeToHumanReadable(t, DataType.Array)
                            : dataTypeToHumanReadable(t, DataType.JsonObj)
                          }
                        </span>
                        <span>
                          {expandObjectDetails ? (
                            <CaretDownFilled  />
                          ) : (
                            <CaretRightFilled />
                          )}
                        </span>
                      </>
                    ): (
                      (portInfo?.lastDetectedValue !== undefined && portInfo?.lastDetectedValue !== null) ? (
                        <>
                          {portInfo?.lastDetectedEventType === DataType.ImageData && (
                            <span>{wpT('Image', 'Image')}</span>
                          )}

                          {portInfo?.lastDetectedEventType === DataType.AudioBuffer && (
                            <span>{wpT('Audio', 'Audio')}</span>
                          )}

                          {(
                            portInfo?.lastDetectedEventType === DataType.String
                            || portInfo?.lastDetectedEventType === DataType.Number
                            || portInfo?.lastDetectedEventType === DataType.Boolean
                          ) && (
                            <span>{portInfo?.lastDetectedValue?.toString()}</span>
                          )}
                        </>
                      ) : ('N/A')
                    )}
                  </div>
                </div>

                <div className={classNames(styles.DetailsPanel,
                  {
                    [styles.Visible]: expandObjectDetails,
                  }
                )}>
                  {
                    (
                      portInfo?.lastDetectedEventType === DataType.JsonObj
                      || portInfo?.lastDetectedEventType === DataType.Array
                      || portInfo?.lastDetectedEventType === DataType.Rect
                      || portInfo?.lastDetectedEventType === DataType.Point
                      || portInfo?.lastDetectedEventType === DataType.BinaryFile
                    ) && (
                      <Inspector
                        data={portInfo?.lastDetectedValue}
                        table={false}
                        // First level always expanded
                        expandLevel={1}
                        // @ts-expect-error bad typings
                        theme={customTheme}
                      />
                    )
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default WidgetPortInspector;
